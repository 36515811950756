/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/shop',
		name: 'shop',
		component: () => import('../views/shop.vue'),
	},
	{
		path: '/enter',
		name: 'enter',
		component: () => import('../views/enter.vue'),
	},
	{
		path: '/shopList',
		name: 'shopList',
		component: () => import('../views/shopList.vue'),
	},
	{
		path: '/shopGoodsAll',
		name: 'shopGoodsAll',
		component: () => import('../views/shopGoodsAll.vue'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/register.vue')
	},
	{
		path: '/error',
		name: 'Error',
		component: () => import('../components/Error.vue')
	},
	{
		path: '/goods',
		name: 'Goods',
		component: () => import('../views/Goods.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/goods/details',
		name: 'Details',
		component: () => import('../views/Details.vue')
	},
	{
		path: '/shoppingCart',
		name: 'ShoppingCart',
		component: () => import('../views/ShoppingCart.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/collect',
		name: 'Collect',
		component: () => import('../views/Collect.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/merchantshop',
		name: 'MerchantShop',
		component: () => import('../views/MerchantShop.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/helpCenter',
		name: 'HelpCenter',
		component: () => import('../views/HelpCenter.vue'),
		meta: {
			requireAuth: false // 需要验证登录状态
		},
		children: [{
			path: '/helpInfo',
			name: 'HelpInfo',
			component: () => import('../views/HelpInfo.vue'),
			meta: {
				requireAuth: false // 需要验证登录状态
			}
		}]
	},
	{
		path: '/personalCenter',
		name: 'PersonalCenter',
		component: () => import('../views/PersonalCenter.vue'),
		meta: {
			requireAuth: false // 需要验证登录状态
		},
		children: [

			{
				path: '/joinus/merchantSettled',
				name: 'MerchantSettled',
				component: () => import('../views/Joinus/MerchantSettled.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/changePassword',
				name: 'ChangePassword',
				component: () => import('../views/PersonalCenter/ChangePassword.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/paymentSettings',
				name: 'PaymentSettings',
				component: () => import('../views/PersonalCenter/PaymentSettings.vue'),
				meta: {
					requireAuth: true ,// 需要验证登录状态
					keepAlive: false
				}
			},	
			{
				path: '/personalCenter/viewLogistics',
				name: 'ViewLogistics',
				component: () => import('../views/PersonalCenter/ViewLogistics.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/personal',
				name: 'Personal',
				component: () => import('../views/PersonalCenter/Personal.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/order',
				name: 'Order',
				component: () => import('../views/PersonalCenter/Order.vue'),
				meta: {
					requireAuth: true // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/address',
				name: 'Address',
				component: () => import('../views/PersonalCenter/Address.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/orderdetail',
				name: 'OrderDetail',
				component: () => import('../views/PersonalCenter/OrderDetail.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/productCollect',
				name: 'ProductCollect',
				component: () => import('../views/PersonalCenter/ProductCollect.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/personalCenter/shopCollect',
				name: 'ShopCollect',
				component: () => import('../views/PersonalCenter/ShopCollect.vue'),
				meta: {
					requireAuth: false, // 需要验证登录状态
				}
			},
			{
				path: '/joinus/aboutUs',
				name: 'AboutUs',
				component: () => import('../views/Joinus/AboutUs.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/joinus/contactUs',
				name: 'ContactUs',
				component: () => import('../views/Joinus/ContactUs.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},

		]
	},
	{
		path: '/confirmOrder',
		name: 'ConfirmOrder',
		component: () => import('../views/ConfirmOrder.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/payQrCode',
		name: 'PayQrCode',
		component: () => import('../views/PayQrCode.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	}
]

const router = new Router({
	// base: '/dist',
	// mode: 'history',
	routes
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
const routerPush = router.push;
router.push = path => {
	// 判断下当前路由是否就是要跳转的路由
	if (router.currentRoute.fullPath.includes(path)) return;
	return routerPush.call(router, path);
}

export default router
