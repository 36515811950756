import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from '@/store'
import {
	getToken,
	removeToken
} from '@/utils/auth'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent
		if (localStorage.getItem("userToken")) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['Authorization'] = `Bearer ${getToken()}`
		}
		

		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug	
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// debugger
		if (res.code === 501) {
			// to re-login
			localStorage.setItem("userToken", '');	
			removeToken()
		} else if (res.code === 400) {
			Message({
				message: res.msg,
				type: 'error',
				duration: 5 * 1000
			})
		}
		return res
	},
	error => {
		console.log('err' + error) // for debug
		Message({
			message: error,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
